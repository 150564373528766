/* fleck*/
html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.fleck-demo {
  width: 100%;
  background-color: var(--background-color);
  background-image: paint(fleck);
}

@supports not (aspect-ratio: 1 / 1) {
  .fleck-demo {
    position: relative;
    overflow: hidden;
    height: 0;
    padding-top: 100%;
  }
}

.fleck-demo[data-theme="login"] {
  --fleck-seed: 12345;
  --fleck-count: 60;
  --fleck-size-base: 10px;
  --fleck-color-1: #58c91bd1;
  --fleck-color-2: #101010;
  --fleck-color-3: #f3f3f3d4;
  background-color: #1a1a83;
  --background-color: #1a1a83;
}


.Loader {
  width: 100%;
  height: 100vh;
  position: absolute;
  background: url("https://thumbs.gfycat.com/SpryNegligibleLeafcutterant-size_restricted.gif")
    center no-repeat;
  background-size: 60px;
  background-color: rgb(221 221 221 / 36%);
  backdrop-filter: blur(3px);
  z-index: 100;
  display: none;
}
.loginWeapper {
  width: 100%;
  height: 93.5vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.form-floating > label {
  padding: 0.3rem 0.75rem !important;
  color: #686868 !important;
}

.form-signin {
  width: 30%;
  margin: 0 auto;
  -webkit-backdrop-filter: blur(15px);
  backdrop-filter: blur(15px);
  background-color: rgba(255, 255, 255, 0.992); 
  padding: 50px;
  border-radius: 10px;
}
.form-label{
  text-align: start !important;
  font-size: 16px;
  font-weight: bold;
  color: black 
}
.form-input{
  border: 1px solid rgb(59, 67, 87) !important
}
.form-input:focus{
  border: none !important
}
.is-invalid{
  border: 1px solid rgb(220, 24, 24) !important
}
.form-check-input{
  border: 1px solid rgb(34, 39, 51) !important
}
.button-container{
  display: flex;
  justify-content: space-around;
}
.cstm-login-btn {
  border: none !important;
}
.cstm-login-cancel {
  /* margin-left: 55px; */
}
.FotGetPassword {
  text-decoration: none;
  color: var(--primaryColor) !important;
}
.errorMessage {
  color: rgb(255, 81, 0);
  padding-top: 3px;
  margin-bottom: 10px;
  font-size: 16px;
}
.showPassword {
  text-align: right;
  font-size: 13px;
  color: #ddd;
}


@media (max-width: 576px) {
  .form-signin{
    width: 90%;
    padding: 30px;
  }
  .button-container{
    display: flex;
    justify-content: space-around;
  }
}
@media (min-width: 576px) {
  .form-signin{
    width: 60%;
    padding: 30px;
  }
  .button-container{
    display: flex;
    justify-content: space-around;
  }
}

@media (min-width: 768px) {
  .form-signin{
    width: 50%;
    padding: 30px;
  }
  .button-container{
    display: flex;
    justify-content: space-around;
  }
}

@media (min-width: 992px) {
  .form-signin{
    width: 40%;
    padding: 30px;
  }
  .button-container{
    display: flex;
    justify-content: space-around;
  }
}

@media (min-width: 1200px) {
  .form-signin{
    width: 35%;
    padding: 30px;
  }
  .button-container{
    display: flex;
    justify-content: space-around;
  }
  
}
@media (min-width: 1400px) {
  .form-signin{
    width: 30%;
    padding: 30px;
  }
  .button-container{
    display: flex;
    justify-content: space-around;
  }
}
